body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  font-family: sans-serif;
}

label[for=iframesCardNumber], label[for=iframesCardExpiration], label[for=iframesCardCvv],label[for=iframesZip] {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 13px;
  color: #555;
  line-height: 1.5;
}

#ss-banner {
  background: transparent url(https://github.com/hps/heartland-php/blob/master/examples/end-to-end/assets/images/ss-shield@2x.png?raw=true) no-repeat left center;
  height: 40px;
  background-size: 280px 34px;
  margin-bottom: 7px;
}

user agent stylesheet
div {
  display: block;
}

.form-row {
margin-top: 10px;
}

.form-wrapper {
display: block;
width: 300px;
margin: 10px auto;
}
input[type=text]:focus {
  border: 3px solid #555;
}

.zip{
  /* -webkit-writing-mode: horizontal-tb !important; */
  text-rendering: auto;
  color: -internal-light-dark(black, white);
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: start;
  appearance: textfield;
  background-color: -internal-light-dark(rgb(255, 255, 255), rgb(59, 59, 59));
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0em;
  font: 400 13.3333px Arial;
  padding: 1px 2px;
  border-width: 2px;
  border-style: inset;
  border-color: -internal-light-dark(rgb(118, 118, 118), rgb(195, 195, 195));
  border-image: initial;
  /* height: 50px; */
  letter-spacing: 3px;
  font-family: sans-serif;
  box-sizing: border-box;
  display: block;
  height: 45px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  width: 100%;
  background: #fff;
  /* border: 1px solid;
  border-color: #bbb3b9 #c7c1c6 #c7c1c6; */
  box-sizing: border-box;
  font-family: serif;
  font-size: 16px;
  line-height: 1;
  margin: 0 .5em 0 0;
  max-width: 100%;
  outline: 0;
}
.loader {
  border: 10px solid rgb(255,255,255); /* Light grey */
  border-top: 10px solid rgb(255,255,255); /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  top:541px;
  left:0;
  bottom:0;
  right:0;
  /* position: fixed; */
  margin:auto;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.hide-loader{
  border: 10px solid rgb(255,255,255);
  border-radius: 50%;
  border-top: 10px solid rgb(0,109,53);
  border-bottom: 10px solid rgb(0,109,53);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top:541px;
  left:0;
  bottom:0;
  right:0;
  /* position: fixed; */
  margin:auto;
}

.hide-loader1{
  border: 10px solid rgb(255,255,255);
  border-radius: 50%;
  border-top: 10px solid rgb(255,255,255);
  border-bottom: 10px solid rgb(255,255,255);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  top:541px;
  left:0;
  bottom:0;
  right:0;
  /* position: fixed; */
  margin:auto;
}
.disableBtn{
pointer-events: none;
/* background-color: red!important; */

}

/* .hide-loader {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
} */

/* Transparent Overlay */
/* .hide-loader:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
} */

/* :not(:required) hides these rules from IE9 and below */




/* Animation */

